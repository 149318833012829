/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
@import "bootstrap";
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


// @import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "assets/fonts/calibri/font.css";

:root {
  --primary_body_color: navy;
  --nav_bar_primary_color: maroon;
  --nav_bar_header_bg_color: white;
  // --nav_bar_secondary_color: rgb(26, 23, 23);
  --nav_bar_secondary_color: #1b1b9b;
  --nav_bar_hover_color: gold;
  // --nav_bar_selected_menu_color: gold;
  --menu_bar_text_color: maroon;
  // --menu_bar_loader_back_color: navy;
  --menu_bar_1_loader_color: rgb(153, 247, 247);
  --menu_bar_2_loader_color: rgb(106, 241, 241);
  --menu_bar_3_loader_color: rgb(91, 242, 242);
  --menu_bar_4_loader_color: rgb(36, 237, 237);
  --menu_bar_5_loader_color: rgb(7, 246, 246);
  --input_field_focus_color: navy;
  --side_menu_text_color: white;
  --solo_button_back_colour: maroon;
  --solo_button_approve_back_colour: rgb(11, 208, 96);
  --solo_button_text_colour: white;
  --solo_button_hover_colour: rgb(172, 3, 3);
  --cancel_button_back_colour: white;
  --cancel_button_text_colour: maroon;
  --cancel_button_hover_colour: rgb(225, 225, 225);
  --table_header_back_color: rgb(74, 8, 8);
  --table_header_text_color: white;
  --table_back_color: white;
  --form--button--color: maroon;
  --form--button--disable--color: gray;
  --form--input--disable--color: rgb(230, 230, 230);
  --placeholder-txt-color: #b0afaf;
}

* {
  // margin: 0;
  // padding: 0;
  // box-sizing: border-box;
  // -webkit-font-smoothing: antialiased;
}

body {
  background: var(--primary_body_color);
  font-family: 'Calibrii', sans-serif;
  padding: 5%;
  overflow: auto;
  line-height: 1.25;
}

$sidebarWidth : 260px;

@media screen and (min-width: 768px) {

  ion-content body.sidebar-pinned {
    left: $sidebarWidth;
    width: calc(100% - $sidebarWidth);
  }
}

.searchSelectContainer {
  height: 3em;
  padding: 0.8em 5px !important;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.disabled {
    background-color: #f0f0f0;
  }
}





.modal-with-content.show .click-trigger-table {
  margin: auto;
  background-color: white;
}

.click-trigger-table,
.click-trigger-table th,
.click-trigger-table td {
  border: 2px solid black;
  padding: 2px 5px;
}

.click-trigger-table th {
  text-transform: capitalize;
}

.modal-with-content {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.modal-with-content.show {
  display: block;
}

/* Modal Content/Box */
.modal-with-content .modal-content {
  border: 1px solid #888;
  max-width: 60vw;
  margin: 10% auto;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
}

/* The Close Button */
.modal-with-content .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modal-with-content .close:hover,
.modal-with-content .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


// COMMON FORM DESIGN
.login-form {

  &:not(.hc-form) {
    width: 500px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
  }

  .select_button {
    z-index: 10;
    position: absolute;
    bottom: -30px;
    right: -20px;
    font-size: 15px;
    color: white;
    margin-right: 20px;
    padding: 7px 7px 5px 7px;
    background-color: #800008;
    border-radius: 5px;
  }

  background: #fff;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);

  .content {
    padding: 20px 35px;
    text-align: left;
  }

  .logo_content {
    width: 100%;
    height: 80px;
    object-fit: contain;
    display: flex;
  }

  .logo_content .logo_image {
    height: 100%;
    margin: auto;
  }

  .form-title {
    font-size: xx-large;
    font-weight: bold;
  }

  .input-field {
    padding: 12px 5px;

    &.ion-select-flex-col {
      display: flex;
      flex-direction: column;

    }

    label {
      font-size: medium;
    }

    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: var(--placeholder-txt-color);
    }

    input {
      font-size: large;
      display: block;
      width: 100%;
      padding: 10px 3px;
      border: 0;
      border-bottom: 1px solid #747474;
      outline: none;
      -webkit-transition: all .2s;
      transition: all .2s;
      font-weight: bold;

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &::placeholder {
        text-transform: uppercase;
      }

      &:focus {
        border-bottom: 2px solid var(--input_field_focus_color);
      }

      &:disabled {
        background-color: var(--form--input--disable--color);
      }
    }

    input[type="date"] {
      &[disabled] {
        border: none
      }

      &.custom-date-control {
        transition: all 0.2s, font-size 0s;

        &:not(.has-value) {
          &::before {
            content: attr(placeholder);
            color: var(--placeholder-txt-color);
            font-size: large;
          }

          &:focus::before {
            content: '' !important;
          }

          &:not(:focus) {
            font-size: 0px;
          }
        }
      }
    }


    ion-item {
      font-size: 16px;
      display: block;
      width: 100%;
      // top: -10px;
      // padding: 0px 0px 20px 0px;
      // height: 29px;
      border: 0;
      border-bottom: 1px solid #747474;
      outline: none;
      -webkit-transition: all .2s;
      transition: all .2s;
      font-weight: bold;

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &::placeholder {
        text-transform: uppercase;
      }

      &:focus {
        border-bottom: 2px solid var(--input_field_focus_color);
      }

      &:disabled {
        background-color: var(--form--input--disable--color);
      }
    }

    textarea {
      width: 100%;
      padding: 5px;
      font-weight: bold;
      font-size: large;

      &:focus,
      &:active {
        border: 2px solid var(--input_field_focus_color) !important;
        outline: 0;
      }

      &[disabled] {
        background-color: var(--form--input--disable--color);
      }
    }

    ion-select {
      font-weight: bold;
      font-size: 16px;
      border-bottom: 1px solid #747474;

      &:focus {
        border-bottom: 2px solid var(--input_field_focus_color);
      }

      &[disabled] {
        background-color: var(--form--input--disable--color);
      }
    }

    ion-item {
      font-weight: bold;
      font-size: 16px;
      border-bottom: 1px solid #747474;

      &:focus {
        --border-color: var(--input_field_focus_color);
      }

      &[disabled] {
        background-color: var(--form--input--disable--color);
      }
    }

    ion-item ion-select {

      font-weight: bold;
      font-size: 16px;
      border-bottom: none;

      &:focus {
        border-bottom: none;
      }

      &[disabled] {
        background-color: var(--form--input--disable--color);
      }

    }
  }

  a.link {
    text-decoration: none;
    color: #232121;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    // text-transform: capitalize;
    display: inline-block;
    margin-top: 20px;
  }

  a.link:hover {
    text-decoration: none;
    color: var(--input_field_focus_color);
    letter-spacing: 0.2px;
    text-transform: capitalize;
    // text-transform: capitalize;
    display: inline-block;
    margin-top: 20px;
  }

  .action {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    // margin: 10px auto;

    button {
      width: 100%;
      // width: fit-content;
      // min-width: 140px;
      margin: auto;
      border: none;
      padding: 10px;
      cursor: pointer;
      text-transform: capitalize;
      // background: #2d3b55;
      background: var(--solo_button_back_colour);
      color: var(--solo_button_text_colour);
      letter-spacing: 0.2px;
      outline: 0;
      -webkit-transition: all .3s;
      transition: all .3s;
      font-weight: 900;

      &[disabled] {
        background-color: var(--form--button--disable--color);
        pointer-events: none;
      }

      &.set_btn {
        background-color: var(--solo_button_back_colour);
        color: var(--solo_button_text_colour);

        &:hover {
          background: var(--solo_button_hover_colour);
        }

        &[disabled] {
          background-color: var(--form--button--disable--color);
          pointer-events: none;
        }
      }

      &.middle_btn {
        background-color: #8891a7;
        color: #e8e9ec;

        &:hover {
          background: #8891a7;
        }
      }

      &.cancel_btn {
        background-color: var(--cancel_button_back_colour);
        color: var(--cancel_button_text_colour);

        &:hover {
          background: var(--cancel_button_hover_colour);
        }
      }
    }
  }

  &.hc-form {
    .input-field {
      padding: 2px 3px;

      ::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        font-size: smaller;
      }
    }

    input {
      padding: 2px 1px;

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &::placeholder {
        font-size: smaller;
      }
    }

    .action {
      button {
        margin-top: 20px;
        max-width: 200px;
        min-width: none;
        display: block;
        border-radius: 25px;
      }

      .full_width_button {
        max-width: none;
        width: 100%;
        border-radius: 25px;
      }

      &[disabled] {
        background-color: var(--form--button--disable--color);
        pointer-events: none;
      }
    }

    .addnew-btn-form {


      .single-btn-otp-btn {
        background-color: var(--solo_button_back_colour);
        border-radius: 25px;

        &[disabled] {
          background-color: var(--form--button--disable--color);
          pointer-events: none;
        }
      }

      .field_button {
        margin-top: -50px;
        // margin-left: 10px;
        z-index: 2;
        // width: 200px;
        // min-width: none;

        &[disabled] {
          background-color: var(--form--button--disable--color);
          pointer-events: none;
        }
      }



      // &[disabled] {
      //   background-color: var(--form--button--disable--color);
      //   pointer-events: none;
      // }

    }

    .cooldown_timer {
      margin-top: 10px;
      margin-right: 10px;
      background-color: var(--solo_button_back_colour);
      border-radius: 25px;
      height: 35px;
      width: 45px;
      color: var(--solo_button_text_colour);
      float: right;
    }

    @media only screen and (max-width: 600px) {
      .addnew-btn-form {
        .single-btn-otp-btn {
          background-color: var(--solo_button_back_colour);

          &[disabled] {
            background-color: var(--form--button--disable--color);
            pointer-events: none;
          }
        }

        .field_button {
          margin-top: 0px;
          min-width: none;

          &[disabled] {
            background-color: var(--form--button--disable--color);
            pointer-events: none;
          }
        }

      }
    }

    ion-select {
      margin-top: 4px;
      font-size: smaller;
      border-bottom: 1px solid #747474;

      &:focus {
        border-bottom: 2px solid var(--input_field_focus_color);
      }
    }

    ion-item {
      margin-top: 4px;
      font-size: smaller;
      border-bottom: 1px solid #747474;

      &:focus {
        border-bottom: 2px solid var(--input_field_focus_color);
      }
    }

  }

}



.search-form {

  background: #fff;
  width: 300px;
  margin: 10px auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);
  float: left;

  .search-action {

    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    // margin: 10px auto;


    input {
      margin-left: 4%;
      margin-bottom: 3px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: medium;
      width: 70%;
      padding: 10px;
      border: 0;
      border-bottom: 1px solid #747474;
      outline: none;
      -webkit-transition: all .2s;
      transition: all .2s;
      font-weight: bold;

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &::placeholder {
        text-transform: uppercase;
      }

      &:focus {
        // border-bottom: 2px solid #6C0BA9;
        // border-bottom: 2px solid navy;
        border-bottom: 2px solid var(--input_field_focus_color);
      }
    }

    button {
      display: inline-block;
      // width: 100%;
      // width: fit-content;
      // min-width: 140px;
      margin: auto;
      border: none;
      padding: 10px;
      cursor: pointer;
      text-transform: capitalize;
      background: var(--solo_button_back_colour);
      color: var(--solo_button_text_colour);
      letter-spacing: 0.2px;
      outline: 0;
      -webkit-transition: all .3s;
      transition: all .3s;
      font-weight: 900;
      margin-right: 2px;

      &[disabled] {
        background-color: var(--form--button--disable--color);
        pointer-events: none;
      }

      &.set_btn {
        background-color: var(--solo_button_back_colour);
        color: var(--solo_button_text_colour);

        &:hover {
          background: var(--solo_button_back_colour);
        }
      }

      &.cancel_btn {
        background-color: var(--cancel_button_back_colour);
        color: var(--cancel_button_text_colour);

        &:hover {
          background: var(--cancel_button_hover_colour);
        }
      }
    }
  }

}


.addnew-btn-form {

  display: inline-block;
  margin: 10px auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  border-radius: 4px;
  // box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);
  float: right;

  .single-btn {
    display: inline-block;
    // width: fit-content;
    // min-width: 140px;
    margin: auto;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    text-transform: capitalize;
    // background: #2d3b55;

    background: var(--solo_button_back_colour);

    // color: #fff;

    color: var(--solo_button_text_colour);

    letter-spacing: 0.2px;
    outline: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
    border-radius: 3px;
    float: right;
    margin-right: 2px;

    &[disabled] {
      background-color: var(--form--button--disable--color);
      pointer-events: none;
    }


  }

  .single-btn-otp-btn {

    display: inline-block;
    // width: fit-content;
    // min-width: 140px;
    margin: auto;
    border: none;
    // padding: 10px 20px;
    height: 35px;
    padding: 5px 20px;
    cursor: pointer;
    text-transform: capitalize;
    background: var(--solo_button_back_colour);
    color: var(--solo_button_text_colour);
    letter-spacing: 0.2px;
    outline: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
    border-radius: 3px;
    float: right;

    &:disabled {
      background: var(--form--button--disable--color);
    }

  }

  .single-btn-otp-btn {

    display: inline-block;
    // width: fit-content;
    // min-width: 140px;
    margin: auto;
    border: none;
    // padding: 10px 20px;
    height: 35px;
    padding: 5px 20px;
    cursor: pointer;
    text-transform: capitalize;
    background: var(--solo_button_back_colour);
    color: var(--solo_button_text_colour);
    letter-spacing: 0.2px;
    outline: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
    border-radius: 3px;
    float: right;

    &:disabled {
      background: var(--form--button--disable--color);
    }

  }


  .parent-task-single-btn {
    display: inline-block;
    // width: fit-content;
    // min-width: 140px;
    margin: auto;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    text-transform: capitalize;
    background: #2d3b55;
    color: #fff;
    letter-spacing: 0.2px;
    outline: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
    border-radius: 3px;
    float: right;
    margin-right: 2px;
    margin-top: -10%;
  }

}

.addnew-qual-btn-form {
  margin: 10px 35px;
  box-shadow: none;

  .single-btn {
    box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);
  }
}

.round-button-big {
  border-radius: 25px
}

.single-btn-left-side-corner {
  display: inline-block;
  // width: fit-content;
  // min-width: 140px;
  margin: auto;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  text-transform: capitalize;
  background: #2d3b55;
  color: #fff;
  letter-spacing: 0.2px;
  outline: 0;
  -webkit-transition: all .3s;
  transition: all .3s;
  border-radius: 3px;
  float: left;
  margin-right: 2px;
}

.addnew-btn-form-in-course {


  display: inline-block;
  margin: 20px 20px 20px auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  border-radius: 4px;

  float: right;

  .single-btn {
    min-width: 200px;
    display: inline-block;
    // width: fit-content;
    // min-width: 140px;
    margin: auto;
    margin-left: 10px;
    margin-top: 10px;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    text-transform: capitalize;
    background: #2d3b55;
    color: #fff;
    letter-spacing: 0.2px;
    outline: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
    border-radius: 3px;
    float: right;
  }


}


.search-btn1 {

  width: 10%;
  // width: fit-content;
  // min-width: 140px;
  margin: auto;
  border: none;
  padding: 10px;
  cursor: pointer;
  text-transform: capitalize;
  background: #2d3b55;
  color: #fff;
  letter-spacing: 0.2px;
  outline: 0;
  -webkit-transition: all .3s;
  transition: all .3s;
  border-radius: 3px;
}

.navbar_header {
  padding-top: 10px;
  // color: #6C0BA9;
  font-weight: 900;
  font-size: xx-large;
}

span.danger {
  color: rgb(224, 5, 5);
  display: none;
}

label {
  float: left !important;
  font-weight: 400;

  &[disabled] {
    color: #777;
  }

  .title {
    color: #fff;
  }
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;

  // hide number row 
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.maintain_space {
  display: block;
}

ion-card-header.ios {
  display: flex;
  flex-flow: column-reverse;
}

.title {
  color: #fff;
}

.card {
  // margin: 1% 0%;
}


.card-body {
  // margin: 0% 0% 0% 5%;
  // padding: 6% 0%;
}

.card-body table tr td {
  float: left;
  // width: 30%;
  // text-align: left;
}

.container {
  margin: 0% 0% 0% 2%;
  background-color: white;
  width: 50%;
}

.card-title {
  margin: 0;
  padding: 0;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.refresh-icon-button {
  padding: 2px 2px;
  margin: 2px 2px;

  margin-top: 25px;
  background-color: #e8e9ec;

  color: #2d3b55;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  place-items: center;
}

.card-select-drop-down {

  box-shadow: none;
  padding: 0;
}

.btn-action {
  padding: 2px 5px;
  margin: 2px 5px;
  background-color: var(--solo_button_back_colour);
  color: var(--solo_button_text_colour);
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  place-items: center;
}


.btn-action-approve {
  padding: 2px 5px;
  margin: 2px 5px;
  background-color: var(--solo_button_approve_back_colour);
  color: var(--solo_button_text_colour);
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  place-items: center;
}

.form_head_left {
  margin-left: 5%;
}

@media screen and (max-width: 760px) {
  .login-form {
    width: 280px !important;
    // margin: 10px auto;

    /*
    .content {
      padding: 35px;
    }
    */

    .input-field {
      // padding: 12px 5px;

      input {
        width: 100%;
        // padding: 10px 1px;
      }
    }

    .action {
      margin: 0;
      width: 100%;

      button {
        width: 100%;
        margin: auto;
      }
    }
  }

  .refresh-icon-button {
    padding: 2px 2px;
    margin: 2px 2px;

    margin-top: 13px;
  }

  .logo_content .logo_image {
    height: 95%;
    width: 90%;
    margin: auto;
  }

}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;

  tr {
    background-color: var(--table_back_color);
    border: 1px solid #ddd;
    padding: .35em;

    &.table-header {
      // background-color: #2d3b55;
      background-color: var(--table_header_back_color);

      color: var(--table_header_text_color);

    }
  }

  th,
  td {
    padding: .625em;
    text-align: center;

  }

  th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: capitalize;
  }
}

@media screen and (max-width: 600px) {


  table {

    border: 56px;

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }

    td {
      // border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;

      &::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: capitalize;
        // background-color: #2d3b55;
        // color: #fff;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    .tc_container {
      display: none;
    }

  }

  body {
    padding-top: 50px;
  }


  .single-btn {
    display: inline-block;
    // width: fit-content;
    // min-width: 140px;
    margin: auto;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    text-transform: uppercase;
    background: #2d3b55;
    color: #fff;
    letter-spacing: 0.2px;
    outline: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
    border-radius: 3px;
    float: right;
  }

  .refresh-icon-button {
    padding: 2px 2px;
    margin: 2px 2px;
    margin-top: 5px;
  }



}


// pagination css
//----------  responsive breakpoints
@mixin breakpoint ($value) {
  @if $value=='phone' {
    @media only screen and (min-width: 120px) and (max-width: 767px) {
      @content;
    }
  }

  @else if $value=='tablet' {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      @content;
    }
  }

  @else if $value=='touch' {
    @media only screen and (min-width: 120px) and (max-width: 1024px) {
      @content;
    }
  }

  @else if $value=='desktop' {
    @media only screen and (min-width: 1025px) {
      @content;
    }
  }

  @else {
    @media only screen and (max-width: $value) {
      @content;
    }
  }
}

// Colors
$white: #fff;
$black: #000;
$grey: #595959;
$grey-dark: #2b2b2b;
$grey-light: #eee;
$purple: #51087E;
$blue: #017ac7;
$green: green;
$maroon: maroon;

//----------  reset
a {
  text-decoration: none;
}

//----------  pagination
.pagination-wrapper {
  text-align: center;
  margin: 10px 0;
}

.pagination {
  display: inline-block;
  height: 70px;
  margin-top: 10px;
  padding: 0 25px;
  border-radius: 0px;
  background-color: $grey-light;

  @include breakpoint(1199px) {
    height: 50px;
    margin-top: 50px;
    padding: 0 10px;
    border-radius: 25px;
  }
}

.page-numbers {
  display: block;
  padding: 0 25px;
  float: left;
  transition: 400ms ease;
  color: $grey;
  font-size: 20px;
  letter-spacing: 0.1em;
  line-height: 70px;
  cursor: pointer;

  &:hover,
  &.current {
    background-color: $purple;
    color: $white;
  }

  &.prev:hover,
  &.next:hover {
    background-color: transparent;
    color: $purple;
  }

  @include breakpoint(1199px) {
    padding: 0 15px;
    font-size: 16px;
    line-height: 50px;
  }

  @include breakpoint(touch) {
    padding: 0 14px;
    display: none;

    &:nth-of-type(2) {
      position: relative;
      padding-right: 50px;

      &::after {
        content: '...';
        position: absolute;
        font-size: 20px;
        top: 0;
        left: 45px;
      }
    }

    &:nth-child(-n+3),
    &:nth-last-child(-n+3) {
      display: block;
    }

    &:nth-last-child(-n+4) {
      padding-right: 14px;

      &::after {
        content: none;
      }
    }
  }
}


#inputPin,
#newPin,
#reNewPin {
  -webkit-text-security: disc !important;
  background-color: #fff;
}


// --------- CSS FOR CARD IN MY COURSES ------------------ 

.mobile-card {
  // display: grid;
  max-width: 60%;
  max-height: auto;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s ease;
}

.mobile-card:hover {
  transform: scale(1.05);
}

/* Card image */
.mobile-card-image-container {
  height: 100%;
  width: 100%;
  padding: 20px;
}

.mobile-card-image {
  height: 100%;
  width: 100%;
  border-radius: 5px 5px 0 0;
  padding: 0 0 0 0;
}

/* Card content */
.mobile-card-content {
  // margin-top: -200px;
  // height: 200px;
  padding: 20px 20px 0 0;
  // padding-left: 30%;
  text-align: left;
}


.mobile-card-title {
  font-size: 24px;
  margin-bottom: 10px;
  margin-left: 0;
  color: black;
  text-align: left;
}

.mobile-card-text {
  font-size: 18px;
  line-height: 1.4;
  color: #555;
  text-align: left;
}

.mobile-card-right-footer {
  float: right;
  padding: 0 0 0 0;
}

.mobile-card-left-footer {
  float: left;
  padding: 0 0 0 0
}

.mobile-card table {
  padding: 0;
  border: none;
  background-color: transparent;
}

.mobile-card tr {
  border: none;
  background-color: transparent;
}

.mobile-card .td_image {
  padding: 0;
  width: 30%;
  border: none;
  background-color: transparent;
}

.mobile-card .td_content {
  text-align: left;
  padding: 0;
}


/* Responsive design */
@media (max-width: 600px) {
  .mobile-card {
    max-width: 100%;
    max-height: auto;
  }

  .mobile-card-content {

    height: auto;
    padding: 0;
  }

  .mobile-card-image-container {
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 10px 10px;
    padding: 0;
  }

  .mobile-card-image {
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 10px 10px;
    padding: 3px;
  }

  .mobile-card-text {
    font-size: 18px;
    line-height: 1.4;
    color: #555;
    text-align: left;
  }

  .mobile-card .td_image {
    border: none;
    background-color: transparent;
    width: 100%;

  }

  .mobile-card .td_content {
    text-align: left;
  }

  .table_footer {
    background: transparent;
    font-size: 18px;
    line-height: 1.4;
  }

  .table_footer td {
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
  }


  .table_footer tr {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
  }

  .rating {
    display: flex;
    flex-direction: row;
    font-size: 35px;
  }

  .rating ion-icon {
    margin-bottom: 5px;
    /* Add margin to separate the stars vertically */
    margin-right: 10px;
  }



  .rating ion-icon[name="star"] {
    color: goldenrod;
  }


}


/* Search bar  */

/* Base styles */
.form-group {
  margin-left: 4%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -3em;
}

.search-field {
  flex: 1;
  max-width: 200px;
  /* Adjust the max-width as needed */
  margin-right: -1px;
  width: 23%;
}

.form-group button {
  padding: 15px;
  margin-top: 3px;
  /* Add space between buttons */
  flex-shrink: 0;
  /* Prevent buttons from shrinking */
}


/* Media query for responsiveness */
@media (max-width: 768px) {
  .form-group {
    //    flex-direction: column;
    //   align-items: stretch;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1em;
  }

  .search-field {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .form-group button {
    padding: 10px;
    width: 23%;
    /* Make buttons take full width */
    margin: 0;
    /* Remove margin between buttons */
    justify-content: center;
    margin-top: -3px;
  }


  .rating {
    display: flex;
    flex-direction: row;
    font-size: 35px;
  }

  .rating ion-icon {
    margin-bottom: 5px;
    /* Add margin to separate the stars vertically */
    margin-right: 10px;
  }



  .rating ion-icon[name="star"] {
    color: goldenrod;
  }


}

.widhthAdd button {
  margin-bottom: -2em;
}

ion-icon {
  // pointer-events:none;
  display: flex;
  padding: 2px;
}


// ion select buttons

.select-alert .alert-button-group.sc-ion-alert-md .alert-button.sc-ion-alert-md {
  // padding-inline-end: 80px;
  margin-right: 90px;
}

.select-alert .alert-button-group.sc-ion-alert-md .alert-button.sc-ion-alert-md.alert-button-role-cancel {
  position: absolute;
  float: right;
  margin-right: 10px;
}


.close-icon-button {

  background-color: #2d3b55;
  color: #e8e9ec;
  float: right;
}





// Profile Picture

.container {
  width: 150px;
  height: 135px;
  display: block;
  margin: 0 auto;
  background-color: black;
  border-radius: 1px;
  margin-bottom: 2em;
}

.profile-pic {
  width: 120px;
  height: 120px;
  background-color: #075E54;
}

.outer {
  width: 100% !important;
  height: 100% !important;
  max-width: 150px !important;
  /* any size */
  max-height: 150px !important;
  /* any size */
  margin: auto;
  // border-radius: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px 150px;
  // background-size: contain;
}

.inner {
  background-color: #075E54;
  width: 25px;
  height: 25px;
  // border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.camera-icon {
  height: 15px;
  width: 15px;
  color: white;
}

.containersize {
  width: 180px;
}




// #get_parent_task {
//   margin-top: -41px;
//   float: right;
//   height: 40px;
//   width: 75px;
//   background: #2d3b55;
//   color: #fff;
//   position: relative;
//   padding: 10px;
//   transition: transform 0.2s ease-in-out;
// }

// #get_parent_task:hover {
//   background: #3c4d6d;
//   transform: translateX(2px);
// }


.alert-wrapper.sc-ion-alert-md {

  max-width: none;
  width: 50%;
}



// QR Attendance

.QR-attendance-details td {
  border-bottom: 1px solid #ddd;
  display: block;
  font-size: 1em;
  text-align: right;
}

.QR-attendance-details td::before {
  content: attr(data-label);
  float: left;
  font-weight: bold;
  // text-transform: capitalize;

}

// #get_parent_task:hover {
//   background: #3c4d6d;
//   transform: translateX(2px);
// }

ion-item {
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --padding-right: 0px;
}


.course_vedio_iframe_container {
  position: relative;
  overflow: hidden;
  width: 100%;
  // height: 1850px;
  // height: 103.5vw;
  height: 40vw;

  // padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

}

.responsive-iframe-course-vedio {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // width: 100%;
  padding-top: 56.25%;
  width: 100%;
  height: 101%;
  padding: 0;
  align-items: center;
}


.pdf_verification_container {
  position: relative;
  overflow: hidden;
  width: 100%;
  // height: 1850px;
  // height: 103.5vw;
  height: 124.5vw;

  // padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

}

/* Then style the iframe to fit in the pdf_verification_container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // width: 100%;
  padding-top: 56.25%;
  width: 100%;
  height: 101%;
  padding: 0;
  align-items: center;
}


// =============================== sweetalert - start =======================================================
.cus-sweet-alert {
  width: 26rem;
  padding: 0.6rem 0;

  .swal2-icon {
    // icon container
    height: 3rem !important;
    width: 3rem !important;
    margin: 0 auto 0;

    i {
      font-size: 2rem !important;
    }

  }

  .swal2-title {
    padding: 0.55em 0.7em 0;
    font-size: 1.5em;
    font-weight: 600;
  }

  .swal2-html-container {
    margin: 0.65em 1.1em 0.18em;
    font-size: 0.9em;
  }

  .swal2-styled {
    padding: 0.5em 0.7em;
    font-size: 0.76em;
  }

}

@media (max-width: 500px) {
  .cus-sweet-alert {
    width: 80%;
    padding: 0.4rem 0;

    .swal2-icon {
      // icon container
      height: 2.5rem !important;
      width: 2.5rem !important;

      i {
        font-size: 1.75rem !important;
      }

    }

    .swal2-title {
      padding: 0.35em 0.5em 0;
      font-size: 1.2em;
    }

    .swal2-html-container {
      margin: 0.45em 0.9em 0.14em;
      font-size: 0.7em;
    }

    .swal2-styled {
      padding: 0.4em 0.5em;
      font-size: 0.7em;
    }

  }

  .rating {
    display: flex;
    flex-direction: row;
    font-size: 35px;
  }

  .rating ion-icon {
    margin-bottom: 5px;
    /* Add margin to separate the stars vertically */
    margin-right: 10px;
  }



  .rating ion-icon[name="star"] {
    color: goldenrod;
  }

}

.rating {
  display: flex;
  flex-direction: row;
  font-size: 35px;
}

.rating ion-icon {
  margin-bottom: 5px;
  /* Add margin to separate the stars vertically */
  margin-right: 10px;
}

.rating ion-icon[name="star"] {
  color: goldenrod;
}


// =============================== sweetalert - end ======================================================='

.segment_arrow_button {
  color: #ffff;
  background-color: maroon;
  width: 200px;
  height: 50px;
}

.segment_arrow_button:hover {
  color: #ffff;
  background-color: maroon;
  width: 200px;
  height: 50px;
}

.left-heading-container {
  display: flex;
  margin-bottom: -10px;
  padding-bottom: 0;
}

.left-heading-inside-form {
  display: flex;
  color: var(--solo_button_back_colour);
  font-weight: bold;
  float: left;
  padding-top: 10px;
  padding-left: 10px;

}

.display_in_pc_only {
  display: block;
}

.display_not_in_pc {
  display: none;
}

.block_display {
  display: block !important;
}

.right_footer_button {
  width: 60px !important;
  margin-right: 10px !important;
  float: right;
}

.big_right_footer_button {
  float: right;
}

.invisible_table {
  table {
    border: 0px solid transparent;
    padding: 0;
    margin: 0;
  }

  tr {
    padding: 0;
    margin: 0;
    border: 0px solid transparent;
  }

  td {
    padding: 0;
    margin: 0;
    text-align: left;
    border: 0px solid transparent;
  }



}


@media screen and (max-width: 760px) {
  .segment_arrow_button {
    color: #ffff;
    background-color: maroon;
    width: 200px;
    height: 50px;
  }

  .display_in_pc_only {
    display: none;
  }

  .display_not_in_pc {
    display: block;
  }

  .segment_arrow_button:hover {
    color: #ffff;
    background-color: maroon;
    width: 200px;
    height: 50px;
  }
}


// ====== spinner in navbar ======

ion-header div.clickblock-overlay {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  cursor: not-allowed;
  // background-color: var(--ion-backdrop-color, #000);
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999;
  touch-action: none;
  display: flex;
  align-items: center;

  // &.show {
  //   display: flex;
  // }

  .spinner-box {
    width: 150px;
    height: 100px;
    display: flex;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);
    // justify-content: center;
  }

  .spinner {
    position: relative;
    margin: auto;
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid #0000;
    border-right-color: rgb(108, 11, 169);
    position: relative;
    animation: s4 1s infinite linear;

    &:before,
    &:after {
      content: "";
      position: absolute;
      inset: -8px;
      border-radius: 50%;
      border: inherit;
      animation: inherit;
      animation-duration: 2s;
    }

    &:after {
      animation-duration: 4s;
    }
  }

  .rhombus-loader {
    width: 36px;
    margin: auto;
    aspect-ratio: 1;
    // color: #6C0BA9;
    color: maroon;
    background: linear-gradient(45deg, #0000 24%, currentColor 0 76%, #0000 0);
    position: relative;
    animation: l23-0 1.5s infinite;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 50%;
      height: 50%;
      background: currentColor;
      transform-origin: top left;
      clip-path: polygon(-2px 0, 0 -1px, 100% 100%, -2px 100%);
      inset: auto auto 0 0;
      animation: l23-1 1.5s infinite;
    }

    &:after {
      inset: 0 0 auto auto;
      transform-origin: bottom right;
      clip-path: polygon(0 0, calc(100% + 2px) 0, calc(100% + 2px) 100%, 100% calc(100% + 1px));
    }
  }
}

@keyframes s4 {
  100% {
    transform: rotate(1turn)
  }
}

@keyframes l23-0 {

  0%,
  50% {
    transform: skewX(0)
  }

  90%,
  100% {
    transform: skewX(-45deg)
  }
}

@keyframes l23-1 {

  40%,
  100% {
    transform: rotate(180deg);
    box-shadow: 0 0 0 3px;
  }
}

// css to make serial no column in table smaller then other columns
.table_serial_no {
  width: 100px !important;
}

@media screen and (max-width: 600px) {
  .table_serial_no {
    width: 100% !important;
  }
}

.green-button {
  background-color: green;
}

.red-button {
  background-color: red;
}


.hide_schroll {
  overflow: hidden;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    /* make scrollbar transparent */
  }
}




.suggestion_box {
  max-height: 100px;
  overflow: scroll;
  position: fixed;
  width: 414px;


}

@media screen and (max-width: 760px) {
  .suggestion_box {
    width: 220px;
  }
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */
}

.suggestion_div {
  button {
    width: 100%;
    text-align: start;
    background: rgb(225, 225, 225);
  }

}



#calendarContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#calendarContainer .date-box {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  background-color: green;
  text-align: center;
  color: white;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#calendarContainer .date-box.holiday {
  background-color: red;
  color: white;
  text-align: center;
}

.float-right {
  float: right !important;
}
/* Wrapper styling for the action sheet to center content */
.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styling for the main action sheet group container */
.action-sheet-group {
  background-color: white;
  margin: auto;
  width: 100%;
  max-width: 400px;         /* Limits width on larger screens */
  max-height: 400px;        /* Adjust this value as needed */
  overflow-y: auto;         /* Enables scroll if content exceeds max height */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding-bottom: 15px;     /* Prevents the last item from being clipped */
}

/* Individual option styling */
.action-sheet-option {
  font-size: 15px;
  color: #000;
  text-align: left;
  padding: 12px 20px;
  border-bottom: 1px solid #e0e0e0;
}

.action-sheet-option:last-child {
  border-bottom: none; /* Removes bottom border from the last item */
}

/* Title styling to remain within the container */
.action-sheet-title {
  font-weight: bold;
  font-size: 18px;
  color: #000;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;
  width: 100%;
}

/* Adjust padding for the last group element to ensure it's fully visible */
.action-sheet-group:last-child {
  padding-bottom: 20px;
}

/* Hide the cancel button by moving it off-screen */
.action-sheet-cancel,
.action-sheet-cancel-button {
  display: none !important; /* Force hide the cancel button */
  visibility: hidden !important;
  height: 0;
  overflow: hidden;
}


/* Optional wrapper for entire overlay to center action sheet on screen */
.ion-overlay-wrapper {
  top: 30%;
}

/* Fixes for additional Ionic styles that may interfere */
.action-sheet-container.sc-ion-action-sheet-md {
  display: block;
  max-height: 80vh;        /* Adds a general height limit on larger screens */
  overflow-y: auto;        /* Adds scroll if height limit is exceeded */
}

.searchable-sel-lab{
  float: left;
  z-index: 10 !important;
}

.searchable-sel-item{
  margin-top: -1.25rem !important;
  padding: 0;
}

.searchable-sel{
  width: 100%;
  margin-top: 1rem !important;
}

.custom-approve-btn {
  --background: maroon;/* Green background */
  --color: #ffffff; /* White text */
  --border-radius: 8px; /* Rounded corners */
}

.custom-approve-btn {
  --background: maroon;/* Green background */
  --color: #ffffff; /* White text */
  --border-radius: 8px; /* Rounded corners */
}


.custom-cancel-btn-approve {
  --background:  rgb(11, 208, 96);/* Red background */
  --color: rgb(246, 246, 246); /* White text */
  --border-radius: 8px; /* Rounded corners */
}
