@font-face {
    font-family: 'Calibrii';
    src: url('Calibri-LightItalic.eot');
    src: local('Calibri Light Italic'), local('Calibri-LightItalic'),
        url('Calibri-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Calibri-LightItalic.woff2') format('woff2'),
        url('Calibri-LightItalic.woff') format('woff'),
        url('Calibri-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Calibrii';
    src: url('Calibri-Italic.eot');
    src: local('Calibri Italic'), local('Calibri-Italic'),
        url('Calibri-Italic.eot?#iefix') format('embedded-opentype'),
        url('Calibri-Italic.woff2') format('woff2'),
        url('Calibri-Italic.woff') format('woff'),
        url('Calibri-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Calibrii';
    src: url('Calibri-Light.eot');
    src: local('Calibri Light'), local('Calibri-Light'),
        url('Calibri-Light.eot?#iefix') format('embedded-opentype'),
        url('Calibri-Light.woff2') format('woff2'),
        url('Calibri-Light.woff') format('woff'),
        url('Calibri-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Calibrii';
    src: url('Calibri-Bold.eot');
    src: local('Calibri Bold'), local('Calibri-Bold'),
        url('Calibri-Bold.eot?#iefix') format('embedded-opentype'),
        url('Calibri-Bold.woff2') format('woff2'),
        url('Calibri-Bold.woff') format('woff'),
        url('Calibri-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Calibrii';
    src: url('Calibri.eot');
    src: local('Calibri'),
        url('Calibri.eot?#iefix') format('embedded-opentype'),
        url('Calibri.woff2') format('woff2'),
        url('Calibri.woff') format('woff'),
        url('Calibri.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Calibrii';
    src: url('Calibri-BoldItalic.eot');
    src: local('Calibri Bold Italic'), local('Calibri-BoldItalic'),
        url('Calibri-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Calibri-BoldItalic.woff2') format('woff2'),
        url('Calibri-BoldItalic.woff') format('woff'),
        url('Calibri-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

